<template>
    <div>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFoundComponent',
  };
  </script>
  
  <style>
  /* Add any styles for the NotFoundComponent here */
  </style>
  