// authMiddleware.js

import store from '@/store'; // Adjust the path accordingly

export default function authMiddleware(to, from, next) {
  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("token");

    if (!isAuthenticated) {
      // Redirect to the login page if not authenticated
      next("/login");
    } else {
      // Continue with the navigation if authenticated
      next();
    }
  } else {
    // Continue with the navigation for routes that don't require authentication
    next();
  }
}

export function loadLocalStorageMiddleware(to, from, next) {
  // Check if the localStorage is loaded
  if (store.state.localStorageLoaded) {
    // If not loaded, wait for it
    store.watch(
      (state) => state.localStorageLoaded,
      (newVal) => {
        if (newVal) {
          next();
        }
      }
    );
  } else {
    // If already loaded, proceed to the next route
    next();
  }
}
