<template>
  <!-- <div class="bg-black">
  hunain
  <div class="chart-container"></div>
 </div> -->

  <div class="wrapper">
    <div class="main-panel">
      <!-- BEGIN : Main Content-->
      <div class="main-content">
        <div class="content-overlay"></div>
        <div class="content-wrapper">
          <div class="row">
            <topcharts :data="chartData"></topcharts>
          </div>

          <div class="row match-height">

            <div class="col-lg-12">
              <div class="card">
                <div class="card-content">
                  <div class="card-body">
                    <div>
                      <DailyStatistics></DailyStatistics>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card">
                <div class="card-content">
                  <div class="card-body">
                    <h4 class="card-title">
                      <span>Recent Added Users</span>

                    </h4>
                    <table id="users-list-datatable" class="table w-100">
                      <thead class="thead-dark">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Family Id</th>
                          <th>Birth Date</th>
                          <th>Gender</th>
                          <th>Place of Birth</th>
                          <th>Phone Verified</th>
                          <th>Setup Completed</th>
                          <!-- <th>Action</th> -->
                        </tr>
                      </thead>
                      <tbody> 
                        <tr v-if="isLoading">
                          <td colspan="100%" class="p-0">
                            <div class="w-100 h-100 d-flex justify-content-center align-items-center py-5">
                              <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden"></span>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr v-else v-for="user in recentUsers" :key="user.id">
                          <td>
                            <img :src="user.base_url + user.thumb_size" alt="Picture" class="img-thumbnail"
                            >
                          </td>
                          <td>{{ displayData(user.first_name) }} {{ displayData(user.middle_name) }} {{
                            displayData(user.last_name) }}</td>
                          <td>{{ displayData(user.email) }}</td>
                          <td>{{ displayData(user.id) }}</td>
                          <td>{{ formatDateTime(user.date_of_birth) }}</td>
                          <td>{{ displayData(user.gender === 2 ? 'Female' : 'Male') }}</td>
                          <td>{{ displayData(user.place_of_birth) }}</td>
                          <td>{{ user.phone_verified ? 'Yes' : 'No' }}</td>
                          <td>{{ user.setup_completed ? 'Yes' : 'No' }}</td>
                          <!-- <td class="d-flex">
                            <div class="dropdown nav-item" data-menu="dropdown">
                              <router-link class="bg-transparent nav-link d-flex align-items-center py-0" to=""
                                data-toggle="dropdown">
                                <i class="ft-more-vertical text-white"></i>
                              </router-link>
                            </div>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-content">
                  <div class="card-body">
                    <h4 class="card-title">
                      <span>Recent Added Events</span>
                    </h4>
                    <table id="events-list-datatable" class="table w-100">
                      <thead class="thead-dark">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Description</th>
                          <th>Link</th>
                          <th>Address</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Status</th>
                          <!-- <th>Action</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="isLoading">
                          <td colspan="100%" class="p-0">
                            <div class="w-100 h-100 d-flex justify-content-center align-items-center py-5">
                              <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden"></span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-else v-for="event in recentEvents" :key="event.id">
                          <td>
                            <img :src="event.cover_photo.base_url + event.cover_photo.thumb_size" alt="Picture" class="img-thumbnail"
                            >
                          </td>
                          <td>{{ displayData(event.name) }}</td>
                          <td>{{ displayData(event.description) }}</td>
                          <td>{{ displayData(event.link) }}</td>
                          <td>{{ displayData(event.address) }}</td>
                          <td>{{ formatDateTime(event.start_date) }}</td>
                          <td>{{ formatDateTime(event.end_date) }}</td>
                          <td>{{ displayData(event.status_id == 1 ? 'Active' : 'Inactive') }}</td>

                          <!-- <td class="d-flex">
                            <div class="dropdown nav-item" data-menu="dropdown">
                              <router-link class="bg-transparent nav-link d-flex align-items-center py-0" to=""
                                data-toggle="dropdown">
                                <i class="ft-more-vertical text-white"></i>
                              </router-link>
                            </div>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-content">
                  <div class="card-body">
                    <h4 class="card-title">
                      <span>Recent Added Cultures</span>
                    </h4>
                    <table id="cultures-list-datatable" class="table w-100">
                      <thead class="thead-dark">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>User Name</th>
                          <th>Created at</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="isLoading">
                          <td colspan="100%" class="p-0">
                            <div class="w-100 h-100 d-flex justify-content-center align-items-center py-5">
                              <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden"></span>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr v-else v-for="culture in recentCultures" :key="culture.id">
                          <td>
                            <img :src="culture.cover_photo.base_url + culture.cover_photo.thumb_size" alt="Picture" class="img-thumbnail"
                            >
                          </td>
                          <td>{{ displayData(culture.name) }}</td>
                          <td>{{ displayData(culture.user.first_name) }} {{ displayData(culture.user.last_name) }}</td>
                          <td>{{ formatDateTime(culture.created_at) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END : End Main Content-->

      <!-- BEGIN : Footer-->

      <!-- End : Footer-->
      <!-- Scroll to top button -->
      <button class="btn btn-primary scroll-top" type="button">
        <i class="ft-arrow-up"></i>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue3-apexcharts";
import Saleschart from "@/components/Charts/Saleschart.vue";
import Topcharts from "@/components/Charts/Topcharts.vue";
import Productorder from "@/components/Charts/Productorder.vue";
import DailyStatistics from "@/components/Charts/DailyStatistics.vue";

export default {
  name: "HomeView",
  components: {
    apexcharts: VueApexCharts,
    Saleschart,
    Topcharts,
    Productorder,
    DailyStatistics,
  },
  data() {

    return {
      chartData: {
        users: 0,
        events: 0,
        culturs: 0,
        deleted_users: 0,
      },
      recentUsers: [],
      recentEvents: [],
      recentCultures: [],
      isLoading: false,
      checkboxStates: {}, // Handle checkbox states for users
      base_url: process.env.VUE_APP_BASE_URL
    };
  },
  mounted() {
    this.fetchChartData();
    this.fetchRecentUsers();
  },
  methods: {
    formatDateTime(dateTime) {
      if (!dateTime) return '-';
      return new Intl.DateTimeFormat('en-GB', {
        day: '2-digit', month: '2-digit', year: 'numeric',
        hour: '2-digit', minute: '2-digit'
      }).format(new Date(dateTime));
    },
    displayData(data) {
      return data ? data : '-';
    },
    fetchChartData() {
      const token = localStorage.getItem("token");
      axios
        .get(`${this.base_url}user-counter`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.chartData = response.data.data;
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchRecentUsers() {
      this.isLoading = true;
      const token = localStorage.getItem("token");
      axios
        .get(`${this.base_url}recent-added-item`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.data.status) {
            this.recentUsers = response.data.data.recent_added_users;
            this.recentEvents = response.data.data.recent_added_event;
            this.recentCultures = response.data.data.recent_added_culture;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.error("Error fetching recent users:", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCheckboxChange(id) {
      this.checkboxStates[id] = !this.checkboxStates[id];
    },
    openModal(type, row) {
      // Implement modal logic for 'hold' and 'status' here
      console.log(type, row);
    },
  },
};
</script>
<style scoped>
@import "../app-assets/css/pages/dashboard2.css";
@import "../app-assets/vendors/css/chartist.min.css";
@import "../app-assets/vendors/css/switchery.min.css";
@import "../app-assets/css/core/menu/horizontal-menu.css";
</style>