<template>
  <div class="saleschart">

    <div class="card-header d-flex justify-content-between align-items-center">
      <h4 class="card-title">Quantitative Data Analysis and Statistics (Users, Event & Statistics)</h4>
      <select v-model="selectedFilter" @change="fetchChartData" class="filter-dropdown ms-auto">
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
      </select>
    </div>

    <!-- Chart component -->
    <apexcharts
      width="100%"
      height="500"
      type="area"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts';
import axios from 'axios';

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      isLoading: true,
      base_url: process.env.VUE_APP_BASE_URL,
      token: localStorage.getItem('token'),
      selectedFilter: 'daily', // Default filter value
      chartOptions: {
        chart: {
          type: 'area',
          height: 450,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          colors: ['#7441db', '#7441db', '#7441db'],
        },
        xaxis: {
          categories: [],
        },
        fill: {
          opacity: 0,
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 10,
          labels: {
            formatter(val) {
              return val.toFixed(0);
            },
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val;
            },
          },
        },
      },
      series: [
        {
          name: 'Users',
          data: [],
        },
        {
          name: 'Events',
          data: [],
        },
        {
          name: 'Cultures',
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.fetchChartData();
  },
  methods: {
    async fetchChartData() {
      this.isloading = true;
      try {
        const response = await axios.get(
          `${this.base_url}get-statistics?filter=${this.selectedFilter}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        if (response.data.status) {
          const { dates, user_count, event_count, culture_count } = response.data.data;

          // Create a new object for chartOptions to ensure reactivity
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: dates,
            },
          };

          // this.chartOptions.xaxis.categories = dates;

          // Update the series data
          this.series = [
            { name: 'Users', data: user_count },
            { name: 'Events', data: event_count },
            { name: 'Cultures', data: culture_count },
          ];
        }
      } catch (error) {
        console.error('Error fetching chart data:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.saleschart #SvgjsG1014 path {
  fill: #2f8be6 !important;
  border-radius: 10px;
}
.saleschart #SvgjsG1034 path {
  fill: #ace0fc !important;
  border-radius: 10px;
}
.saleschart .apexcharts-toolbar {
  display: none;
}
.filter-dropdown {
  margin-bottom: 20px;
  padding: 5px;
}
</style>
