<template>

  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card gradient-purple-love">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{ data.users }}</h3>
              <span>Total Users</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-activity font-large-1"></i>
            </div>
          </div>
        </div>
        <div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
          <svg xmlns:ct="http://gionkunz.github.com/chartist-js/ct" width="100%" height="100%" class="ct-chart-line"
            style="width: 100%; height: 100%;">
            <g class="ct-grids">
              <line x1="10" x2="10" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="45.25555759006076" x2="45.25555759006076" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="80.51111518012152" x2="80.51111518012152" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="115.76667277018228" x2="115.76667277018228" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="151.02223036024304" x2="151.02223036024304" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="186.2777879503038" x2="186.2777879503038" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="221.53334554036456" x2="221.53334554036456" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="256.7889031304253" x2="256.7889031304253" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="292.0444607204861" x2="292.0444607204861" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="327.3000183105469" x2="327.3000183105469" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
            </g>
            <g>
              <g class="ct-series ct-series-a">
                <path
                  d="M10,51.667C45.256,60.833,45.256,60.833,45.256,60.833C80.511,33.333,80.511,33.333,80.511,33.333C115.767,42.5,115.767,42.5,115.767,42.5C151.022,15,151.022,15,151.022,15C186.278,42.5,186.278,42.5,186.278,42.5C221.533,33.333,221.533,33.333,221.533,33.333C256.789,42.5,256.789,42.5,256.789,42.5C292.044,24.167,292.044,24.167,292.044,24.167C327.3,38.833,327.3,38.833,327.3,38.833"
                  class="ct-line"></path>
                <line x1="10" y1="51.66666666666667" x2="10.01" y2="51.66666666666667" class="ct-point" ct:value="50">
                </line>
                <line x1="45.25555759006076" y1="60.833333333333336" x2="45.26555759006076" y2="60.833333333333336"
                  class="ct-point" ct:value="45"></line>
                <line x1="80.51111518012152" y1="33.333333333333336" x2="80.52111518012153" y2="33.333333333333336"
                  class="ct-point" ct:value="60"></line>
                <line x1="115.76667277018228" y1="42.5" x2="115.77667277018229" y2="42.5" class="ct-point"
                  ct:value="55"></line>
                <line x1="151.02223036024304" y1="15" x2="151.03223036024303" y2="15" class="ct-point" ct:value="70">
                </line>
                <line x1="186.2777879503038" y1="42.5" x2="186.2877879503038" y2="42.5" class="ct-point" ct:value="55">
                </line>
                <line x1="221.53334554036456" y1="33.333333333333336" x2="221.54334554036456" y2="33.333333333333336"
                  class="ct-point" ct:value="60"></line>
                <line x1="256.7889031304253" y1="42.5" x2="256.7989031304253" y2="42.5" class="ct-point" ct:value="55">
                </line>
                <line x1="292.0444607204861" y1="24.166666666666664" x2="292.0544607204861" y2="24.166666666666664"
                  class="ct-point" ct:value="65"></line>
                <line x1="327.3000183105469" y1="38.83333333333333" x2="327.31001831054687" y2="38.83333333333333"
                  class="ct-point" ct:value="57"></line>
              </g>
            </g>
            <g class="ct-labels"></g>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card gradient-ibiza-sunset">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{ data.events }}</h3>
              <span>Total Events</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-percent font-large-1"></i>
            </div>
          </div>
        </div>
        <div id="Widget-line-chart1" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
          <svg xmlns:ct="http://gionkunz.github.com/chartist-js/ct" width="100%" height="100%" class="ct-chart-line"
            style="width: 100%; height: 100%;">
            <g class="ct-grids">
              <line x1="10" x2="10" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="45.25555759006076" x2="45.25555759006076" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="80.51111518012152" x2="80.51111518012152" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="115.76667277018228" x2="115.76667277018228" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="151.02223036024304" x2="151.02223036024304" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="186.2777879503038" x2="186.2777879503038" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="221.53334554036456" x2="221.53334554036456" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="256.7889031304253" x2="256.7889031304253" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="292.0444607204861" x2="292.0444607204861" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="327.3000183105469" x2="327.3000183105469" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
            </g>
            <g>
              <g class="ct-series ct-series-a">
                <path
                  d="M10,15C45.256,42.5,45.256,42.5,45.256,42.5C80.511,28.75,80.511,28.75,80.511,28.75C115.767,56.25,115.767,56.25,115.767,56.25C151.022,42.5,151.022,42.5,151.022,42.5C186.278,52.125,186.278,52.125,186.278,52.125C221.533,27.375,221.533,27.375,221.533,27.375C256.789,59,256.789,59,256.789,59C292.044,35.625,292.044,35.625,292.044,35.625C327.3,42.5,327.3,42.5,327.3,42.5"
                  class="ct-line"></path>
                <line x1="10" y1="15" x2="10.01" y2="15" class="ct-point" ct:value="80"></line>
                <line x1="45.25555759006076" y1="42.5" x2="45.26555759006076" y2="42.5" class="ct-point" ct:value="60">
                </line>
                <line x1="80.51111518012152" y1="28.75" x2="80.52111518012153" y2="28.75" class="ct-point"
                  ct:value="70"></line>
                <line x1="115.76667277018228" y1="56.25" x2="115.77667277018229" y2="56.25" class="ct-point"
                  ct:value="50"></line>
                <line x1="151.02223036024304" y1="42.5" x2="151.03223036024303" y2="42.5" class="ct-point"
                  ct:value="60"></line>
                <line x1="186.2777879503038" y1="52.125" x2="186.2877879503038" y2="52.125" class="ct-point"
                  ct:value="53"></line>
                <line x1="221.53334554036456" y1="27.375" x2="221.54334554036456" y2="27.375" class="ct-point"
                  ct:value="71"></line>
                <line x1="256.7889031304253" y1="59" x2="256.7989031304253" y2="59" class="ct-point" ct:value="48">
                </line>
                <line x1="292.0444607204861" y1="35.625" x2="292.0544607204861" y2="35.625" class="ct-point"
                  ct:value="65"></line>
                <line x1="327.3000183105469" y1="42.5" x2="327.31001831054687" y2="42.5" class="ct-point" ct:value="60">
                </line>
              </g>
            </g>
            <g class="ct-labels"></g>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card gradient-mint">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{ data.culturs }}</h3>
              <span>Total Culturs</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-trending-up font-large-1"></i>
            </div>
          </div>
        </div>
        <div id="Widget-line-chart2" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
          <svg xmlns:ct="http://gionkunz.github.com/chartist-js/ct" width="100%" height="100%" class="ct-chart-line"
            style="width: 100%; height: 100%;">
            <g class="ct-grids">
              <line x1="10" x2="10" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="45.25555759006076" x2="45.25555759006076" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="80.51111518012152" x2="80.51111518012152" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="115.76667277018228" x2="115.76667277018228" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="151.02223036024304" x2="151.02223036024304" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="186.2777879503038" x2="186.2777879503038" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="221.53334554036456" x2="221.53334554036456" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="256.7889031304253" x2="256.7889031304253" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="292.0444607204861" x2="292.0444607204861" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="327.3000183105469" x2="327.3000183105469" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
            </g>
            <g>
              <g class="ct-series ct-series-a">
                <path
                  d="M10,60.833C45.256,42.5,45.256,42.5,45.256,42.5C80.511,49.833,80.511,49.833,80.511,49.833C115.767,24.167,115.767,24.167,115.767,24.167C151.022,51.667,151.022,51.667,151.022,51.667C186.278,29.667,186.278,29.667,186.278,29.667C221.533,37,221.533,37,221.533,37C256.789,15,256.789,15,256.789,15C292.044,55.333,292.044,55.333,292.044,55.333C327.3,38.833,327.3,38.833,327.3,38.833"
                  class="ct-line"></path>
                <line x1="10" y1="60.833333333333336" x2="10.01" y2="60.833333333333336" class="ct-point" ct:value="45">
                </line>
                <line x1="45.25555759006076" y1="42.5" x2="45.26555759006076" y2="42.5" class="ct-point" ct:value="55">
                </line>
                <line x1="80.51111518012152" y1="49.83333333333333" x2="80.52111518012153" y2="49.83333333333333"
                  class="ct-point" ct:value="51"></line>
                <line x1="115.76667277018228" y1="24.166666666666664" x2="115.77667277018229" y2="24.166666666666664"
                  class="ct-point" ct:value="65"></line>
                <line x1="151.02223036024304" y1="51.66666666666667" x2="151.03223036024303" y2="51.66666666666667"
                  class="ct-point" ct:value="50"></line>
                <line x1="186.2777879503038" y1="29.666666666666664" x2="186.2877879503038" y2="29.666666666666664"
                  class="ct-point" ct:value="62"></line>
                <line x1="221.53334554036456" y1="37" x2="221.54334554036456" y2="37" class="ct-point" ct:value="58">
                </line>
                <line x1="256.7889031304253" y1="15" x2="256.7989031304253" y2="15" class="ct-point" ct:value="70">
                </line>
                <line x1="292.0444607204861" y1="55.333333333333336" x2="292.0544607204861" y2="55.333333333333336"
                  class="ct-point" ct:value="48"></line>
                <line x1="327.3000183105469" y1="38.83333333333333" x2="327.31001831054687" y2="38.83333333333333"
                  class="ct-point" ct:value="57"></line>
              </g>
            </g>
            <g class="ct-labels"></g>
          </svg>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-md-6 col-12">
    <div class="card gradient-king-yna">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body white text-left">
              <h3 class="font-large-1 white mb-0">{{ data.deleted_users }}</h3>
              <span>Deleted Users</span>
            </div>
            <div class="media-right white text-right">
              <i class="ft-credit-card font-large-1"></i>
            </div>
          </div>
        </div>
        <div id="Widget-line-chart3" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
          <svg xmlns:ct="http://gionkunz.github.com/chartist-js/ct" width="100%" height="100%" class="ct-chart-line"
            style="width: 100%; height: 100%;">
            <g class="ct-grids">
              <line x1="10" x2="10" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="45.25555759006076" x2="45.25555759006076" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="80.51111518012152" x2="80.51111518012152" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="115.76667277018228" x2="115.76667277018228" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="151.02223036024304" x2="151.02223036024304" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="186.2777879503038" x2="186.2777879503038" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="221.53334554036456" x2="221.53334554036456" y1="15" y2="70" class="ct-grid ct-horizontal">
              </line>
              <line x1="256.7889031304253" x2="256.7889031304253" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="292.0444607204861" x2="292.0444607204861" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
              <line x1="327.3000183105469" x2="327.3000183105469" y1="15" y2="70" class="ct-grid ct-horizontal"></line>
            </g>
            <g>
              <g class="ct-series ct-series-a">
                <path
                  d="M10,37C45.256,53.5,45.256,53.5,45.256,53.5C80.511,15,80.511,15,80.511,15C115.767,60.1,115.767,60.1,115.767,60.1C151.022,42.5,151.022,42.5,151.022,42.5C186.278,48,186.278,48,186.278,48C221.533,28.2,221.533,28.2,221.533,28.2C256.789,53.5,256.789,53.5,256.789,53.5C292.044,26,292.044,26,292.044,26C327.3,39.2,327.3,39.2,327.3,39.2"
                  class="ct-line"></path>
                <line x1="10" y1="37" x2="10.01" y2="37" class="ct-point" ct:value="70"></line>
                <line x1="45.25555759006076" y1="53.5" x2="45.26555759006076" y2="53.5" class="ct-point" ct:value="55">
                </line>
                <line x1="80.51111518012152" y1="15" x2="80.52111518012153" y2="15" class="ct-point" ct:value="90">
                </line>
                <line x1="115.76667277018228" y1="60.1" x2="115.77667277018229" y2="60.1" class="ct-point"
                  ct:value="49"></line>
                <line x1="151.02223036024304" y1="42.5" x2="151.03223036024303" y2="42.5" class="ct-point"
                  ct:value="65"></line>
                <line x1="186.2777879503038" y1="48" x2="186.2877879503038" y2="48" class="ct-point" ct:value="60">
                </line>
                <line x1="221.53334554036456" y1="28.200000000000003" x2="221.54334554036456" y2="28.200000000000003"
                  class="ct-point" ct:value="78"></line>
                <line x1="256.7889031304253" y1="53.5" x2="256.7989031304253" y2="53.5" class="ct-point" ct:value="55">
                </line>
                <line x1="292.0444607204861" y1="26" x2="292.0544607204861" y2="26" class="ct-point" ct:value="80">
                </line>
                <line x1="327.3000183105469" y1="39.2" x2="327.31001831054687" y2="39.2" class="ct-point" ct:value="68">
                </line>
              </g>
            </g>
            <g class="ct-labels"></g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.ct-grid {
  stroke-dasharray: 0px;
  stroke: rgba(0, 0, 0, 0.1);
}

.ct-label {
  font-size: 0.9rem;
}

.WidgetlineChart .ct-point {
  stroke-width: 0px;
}

.WidgetlineChart .ct-line {
  stroke: rgba(255, 255, 255, 0.6) !important;
}

.WidgetlineChart .ct-grid {
  stroke-dasharray: 0px;
  stroke: transparent !important;
}

.WidgetlineChartshadow {
  -webkit-filter: drop-shadow(0px 13px 6px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 13px 6px rgba(0, 0, 0, 0.5));
  /* Same syntax as box-shadow, except for the spread property */
}

.lineArea .ct-line {
  stroke-width: 0px;
}

.lineArea .ct-point {
  stroke-width: 0px;
}

.lineArea .ct-series-a .ct-area {
  fill-opacity: 0.7;
  fill: url(#gradient1) !important;
}

.lineArea .ct-series-b .ct-area {
  fill: url(#gradient) !important;
  fill-opacity: 0.9;
}

.Stackbarchart .ct-series-a .ct-bar {
  stroke: url(#linear) !important;
}

.Stackbarchart .ct-series-b .ct-bar {
  stroke: #eee;
}

.shopping-cart i.ft-trash-2 {
  cursor: pointer;
}

.shopping-cart i.ft-trash-2:hover {
  color: #F55252;
}

.shopping-cart table td {
  vertical-align: middle;
}

.lineAreaDashboard .ct-line {
  fill: none;
  stroke-width: 2px;
}

.lineAreaDashboard .ct-point-circle {
  stroke-width: 2px;
  fill: #fff;
}

.lineAreaDashboard .ct-series-a .ct-area {
  fill: url(#gradient2) !important;
  fill-opacity: 0.8;
}

.lineAreaDashboard .ct-series-a .ct-line {
  stroke: #60AFF0;
}

.lineAreaDashboard .ct-series-a .ct-point-circle {
  stroke: #2F8BE6;
}

.lineAreaDashboard .ct-series-b .ct-area {
  fill: url(#gradient3) !important;
  fill-opacity: 0.8;
}

.lineAreaDashboard .ct-series-b .ct-line {
  stroke: #6CD975;
}

.lineAreaDashboard .ct-series-b .ct-point-circle {
  stroke: #40C057;
}

.lineChart .ct-line {
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
}

.lineChart .ct-label {
  color: #fff;
}

.lineChart .ct-point-circle {
  stroke-width: 2px;
  fill: #fff;
}

.lineChart .ct-series-a .ct-point-circle,
.lineChart .ct-series-a .ct-point {
  stroke: #fff;
}

.lineChartShadow {
  -webkit-filter: drop-shadow(0px 25px 8px rgba(0, 0, 0, 0.5));
  filter: drop-shadow(0px 25px 8px rgba(0, 0, 0, 0.5));
  /* Same syntax as box-shadow, except for the spread property */
}

.BarChart .ct-series-a .ct-bar:nth-of-type(4n+1) {
  stroke: url(#gradient7);
}

.BarChart .ct-series-a .ct-bar:nth-of-type(4n+2) {
  stroke: url(#gradient5);
}

.BarChart .ct-series-a .ct-bar:nth-of-type(4n+3) {
  stroke: url(#gradient6);
}

.BarChart .ct-series-a .ct-bar:nth-of-type(4n+4) {
  stroke: url(#gradient4);
}

.BarChartShadow {
  -webkit-filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.3));
  /* Same syntax as box-shadow, except for the spread property */
}

.donut .ct-label {
  font-size: 20px;
}

.donut .ct-done .ct-slice-donut {
  stroke: #40C057;
  stroke-width: 24px !important;
}

.donut .ct-progress .ct-slice-donut {
  stroke: #F77E17;
  stroke-width: 16px !important;
}

.donut .ct-outstanding .ct-slice-donut {
  stroke: #975AFF;
  stroke-width: 8px !important;
}

.donut .ct-started .ct-slice-donut {
  stroke: #2F8BE6;
  stroke-width: 32px !important;
}
</style>