<template>
  <nav class="navbar navbar-expand-lg navbar-light header-navbar navbar-static">
    <div class="container-fluid navbar-wrapper">
      <div class="navbar-header d-flex">
        <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
          data-toggle="collapse">
          <i class="ft-menu font-medium-3"></i>
        </div>

        <ul class="navbar-nav">
          <!-- Fullscreen Toggle -->
          <li class="nav-item mr-2 d-none d-lg-block">
            <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" @click="toggleFullScreen">
              <i class="ft-maximize font-medium-3"></i>
            </a>
          </li>

          <!-- Search Input -->
          <li class="nav-item nav-search">
            <a class="nav-link nav-link-search" @click="searchOpen = !searchOpen">
              <i class="ft-search font-medium-3"></i>
            </a>
            <div :class="['search-input', searchOpen ? 'open' : '']">
              <div class="search-input-icon"><i class="ft-search font-medium-3"></i></div>
              <input class="input" type="text" placeholder="Search employee..." v-model="searchVal" tabindex="0"
                data-search="template-search" />
              <div class="search-input-close" @click="searchOpen = false">
                <i class="ft-x font-medium-3"></i>
              </div>
              <ul :class="['search-list mt-0', (options.length && searchOpen) || fetching ? 'd-block' : '']">
                <li v-for="(item, idx) in options" :key="idx">
                  <router-link @click="searchOpen = false" class="p-2 d-inline-block w-100 h-100"
                    :to="`/manage-employee/edit/${item.value}/?tab=0`">
                    {{ item.label }}
                  </router-link>
                </li>
                <li v-if="fetching" class="text-center py-3">
                  <span>Loading...</span>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <ul class="navbar-nav d-none">
          <li class="nav-item mr-2 d-none d-lg-block">
            <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;"><i
                class="ft-maximize font-medium-3"></i></a>
          </li>
        </ul>
        <div class="navbar-brand-center">
          <div class="navbar-header">
            <ul class="navbar-nav">
              <li class="nav-item">
                <div class="logo">
                  <router-link class="logo-text" to="/">
                    <div class="">
                      <img class="logo-img w-auto" alt="Kinfolk_logo" src="../app-assets/img/logo.png" />
                    </div>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="navbar-container">
        <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="dropdown nav-item mr-1">
              <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-center" id="dropdownBasic2"
                href="javascript:;" data-toggle="dropdown">
                <div class="user d-md-flex d-none mr-2">
                  <span class="text-right">Dashboard User</span>
                </div>
                <img class="avatar" src="../app-assets/img/portrait/small/avatar-s-1.png" alt="avatar" height="35"
                  width="35" />
              </a>
              <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2">

                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click="handleLogout">
                  <div class="d-flex align-items-center">
                    <i class="ft-power mr-2"></i><span>Logout</span>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div
    class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-sticky navbar-light navbar-shadow menu-border navbar-brand-center bg-glass-2"
    role="navigation" data-menu="menu-wrapper">
    <!-- Horizontal menu content-->
    <div class="navbar-container main-menu-content center-layout" data-menu="menu-container">

      <ul class="navigation-main nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
        <li class="dropdown nav-item" data-menu="dropdown">
          <router-link class="dropdown-toggle nav-link d-flex align-items-center" to="/" active-class="nav-active"
            data-toggle="dropdown"><i class="ft-home"></i><span data-i18n="Dashboard">Dashboard</span></router-link>
        </li>

        <li class="dropdown nav-item " data-menu="dropdown">
          <router-link class="dropdown-toggle nav-link d-flex align-items-center" to="/user" data-toggle="dropdown"><i
              class="icon-user"></i><span>Users</span></router-link>
        </li>
        <li class="dropdown nav-item " data-menu="dropdown">
          <router-link class="dropdown-toggle nav-link d-flex align-items-center" to="" data-toggle="dropdown"><i
              class="icon-star"></i><span>Feedback & Waitlist</span></router-link>
          <ul class="dropdown-menu">
            <li class="dropdown">
              <router-link class="dropdown-item d-flex align-items-center" to="/feedback"><i
                  class="ft-arrow-right submenu-icon"></i><span data-i18n="Feedback">Feedback</span></router-link>
            </li>
            <li class="dropdown">
              <router-link class="dropdown-item d-flex align-items-center" to="/waitlist"><i
                  class="ft-arrow-right submenu-icon"></i><span data-i18n="Waitlist">Waitlist</span></router-link>
            </li>
          </ul>
        </li>

        <li class="dropdown nav-item " data-menu="dropdown">
          <router-link class="dropdown-toggle nav-link d-flex align-items-center" to="/events" data-toggle="dropdown"><i
              class="icon-calendar"></i><span>Events</span></router-link>
        </li>
        <li class="dropdown nav-item " data-menu="dropdown">
          <router-link class="dropdown-toggle nav-link d-flex align-items-center" to="/cultures"
            data-toggle="dropdown"><i class="icon-users"></i><span>Cultures</span></router-link>
        </li>
        <li class="dropdown nav-item " data-menu="dropdown">
          <router-link class="dropdown-toggle nav-link d-flex align-items-center" to="/notification"
            data-toggle="dropdown"><i class="icon-bell"></i><span>Notifications</span></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      searchOpen: false,
      searchVal: "",
      fetching: false,
      options: [],
      fetchRef: 0,
      debounceTimeout: 300, // adjust the debounce timeout as needed
    };
  },
  methods: {
    toggleFullScreen() {
      const doc = document.documentElement;

      if (!document.fullscreenElement) {
        doc.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    },
    handleLogout() {
      let token = localStorage.getItem("token");

      axios.get(`${process.env.VUE_APP_BASE_URL}user/logout`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then((response) => {
        })
        .catch((error) => {
          console.error(error);
        }).finally(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          this.$router.push("/login");
        });
    },
    debounceFetcher(searchVal) {
      clearTimeout(this.timer);
      this.setOptions([]);
      this.setFetching(true);

      this.timer = setTimeout(() => {
        this.fetchRef += 1;
        const fetchId = this.fetchRef;

        this.fetchOptions(searchVal).then((newOptions) => {
          if (fetchId !== this.fetchRef) {
            return;
          }

          this.setOptions(newOptions);
          this.setFetching(false);
        });
      }, this.debounceTimeout);
    },
    fetchOptions(searchVal) {
      // Implement your search API logic here
      return new Promise((resolve) => {
        // Mocked example for options
        resolve([
          { label: `No result found`, value: '1' },
          // Add more results as needed
        ]);
      });
    },
    setOptions(options) {
      this.options = options;
    },
    setFetching(fetching) {
      this.fetching = fetching;
    }
  },
  watch: {
    searchVal(newVal) {
      this.debounceFetcher(newVal);
    }
  }

};
</script>

<style scoped></style>